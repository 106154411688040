import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { theme } from "../../Global/styles/theme";
import ThowColumLayoutLogin from "../../layouts/ThowColumLayout";
import Input from "../../components/Input";

import lostpasswordIcon from "../../images/svg/lostpassword.svg";
import companyIcon from "../../logo.svg";
import { useGlobal } from "../../context/UserContext";
import api from "../../services/api";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LostPassword = () => {
  const [mail, setMail] = useState();
  const [mailError, setMailError] = useState(false);

  const { setLostPassword } = useGlobal();

  /* BTN STATE */

  const [btnState, setBtnState] = useState();
  const navigate = useNavigate();

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const onSubmitSendMail = async () => {
    if (!mail) {
      return toast.error("Preencha o campo de e-mail", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (!validateEmail(mail)) {
      return toast.error("Email inválido", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    const data = {
      mail: mail,
    };

    try {
      const response = await api.post("send/email", data);

      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      localStorage.setItem("@PLAY_TELEMATICS_MAIL:", mail);

      setTimeout(() => {
        navigate("/send/email");
      }, 1300);
    } catch (error) {
      toast.error(error.response.data.error, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const buttonStyle = {
    backgroundColor: theme.colors.colorPrimary,
    width: "191px",
    height: "45px",
    borderRadius: "50px",
    border: "none",
    color: "#fff",
  };

  const handleSend = async () => {
    const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (mail && regexEmail.test(mail)) {
      setMail(mail);
      setLostPassword(mail);
      navigate("/sentemail");
      await api.post("/client/lostPassword", mail); // nao existe essa rota ainda
      // JOGAR O EMAIL PRA API, VERIFICAR SE EXISTE, CASO EXISTA ENVIAR O EMAIL
      return false;
    }
    setMailError(true);
  };

  useEffect(() => {
    setMailError(false);
  }, [mail]);

  return (
    <ThowColumLayoutLogin
      page={"Landing"}
      colum1={
        <div style={{ position: "fixed" }}>
          <img src={lostpasswordIcon} style={{ height: "100vh" }} alt="" />
        </div>
      }
      colum2={
        <div
          style={{
            height: "100vh",
            paddingTop: "100px",
            paddingBottom: "50px",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            width: "418px",
          }}
        >
          <div>
            <img src={companyIcon} alt="" style={{ width: "235px" }} />
            <h2
              className="textPrimaryLanding"
              style={{ color: theme.colors.colorPrimary }}
            >
              Esqueceu a senha ?
            </h2>
            <p className="textPrimaryLabelInput">
              Não se preocupe! Digite seu e-mail para receber instruções de
              recuperação de senha.
            </p>
          </div>
          <Input
            name="E-mail"
            value={mail}
            borderColor={mailError ? theme.colors.red3 : theme.colors.border}
            onChange={(e) => {
              setMail(e.target.value);
            }}
            placeholder="Insira seu e-mail"
            type={"text"}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "418px",
            }}
          >
            <button style={buttonStyle} onClick={onSubmitSendMail}>
              Recuperar senha
            </button>

            <p
              style={{ marginTop: "15px", cursor: "pointer" }}
              onClick={() => navigate("/")}
            >
              Lembrou da senha?
            </p>
          </div>
          <div style={{ paddingBottom: "100px" }} />
        </div>
      }
    ></ThowColumLayoutLogin>
  );
};

export default LostPassword;
