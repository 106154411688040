import React, { useState, useEffect, useContext } from "react";
import { ToastContainer } from "react-toastify";
import ThowColumLayoutInventary from "../../layouts/ThowColumLayoutInventary";
import Sidebar from "../../components/Sidebar";
import HeaderDashboard from "../../components/HeaderDashboard";
import InputSelectFilterManager from "../../components/InputSelectFilterManager";
import { useGlobal } from "../../context/UserContext";

import api from "../../services/api";
import TableListUser from "../../components/TableListUser";
import { theme } from "../../Global/styles/theme";
import InputSelectFilterManagerUsers from "../../components/InputSelectFilterManagerUsers";

const Verifications = () => {
  const { updateResponse } = useGlobal();
  const [modalEdit, setModalEdit] = useState(false);
  const [selectUser, setSelectUser] = useState({});
  const [typeUser, setTypeUser] = useState("");

  const getData = async () => {
    try {
      // const token = localStorage.getItem('@USER_TOKEN');
      // api.setHeader("Authorization", token);

      const response = await api.get("/client/profile/one");
      if (response.status === 200) {
        setTypeUser(response.data.type);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("@USER_TOKEN")) {
      localStorage.clear();
      window.location.href = "/";
    }
    getData();
  }, [updateResponse]);

  const rowFilters = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const buttonStyle = {
    backgroundColor: theme.colors.colorPrimary,
    width: "191px",
    height: "45px",
    borderRadius: "50px",
    border: "none",
    color: "#fff",
  };

  return (
    <ThowColumLayoutInventary
      colum1={<Sidebar page={"access"} />}
      colum2={
        <div
          id={"div-main-access"}
          style={{
            paddingRight: "4rem",
            paddingLeft: "4rem",
            paddingTop: "2.68rem",
            backgroundColor: theme.colors.background,
          }}
        >
          <ToastContainer />
          <HeaderDashboard name="Gerenciar acessos" />
          <div style={rowFilters}>
            <InputSelectFilterManagerUsers />
            <button
              type="button"
              style={buttonStyle}
              onClick={() => {
                setSelectUser({});
                setModalEdit(true);
              }}
            >
              Novo usuário
            </button>
          </div>
          <TableListUser
            modalEdit={modalEdit}
            setModalEdit={setModalEdit}
            selectUser={selectUser}
            setSelectUser={setSelectUser}
          />
        </div>
      }
    ></ThowColumLayoutInventary>
  );
};

export default Verifications;
