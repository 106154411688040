import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import api from "../../services/api";
import Input from "../../components/Input";
import { theme } from "../../Global/styles/theme";
import { useGlobal } from "../../context/UserContext";

import PowerBi from "../../images/png/microsoft_powerbi_logo_icon_169958.png";
import companyIcon from "../../logo.svg";

const Landing = () => {
  const { setCurrentClient } = useGlobal();
  const navigate = useNavigate();

  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");

  /* MESSAGE ERROS */
  const [mailError, setMailError] = useState();
  const [paswordError, setPasswordError] = useState();

  const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const getLogin = async () => {
    try {
      if (
        !mail ||
        !regexEmail.test(mail) ||
        !password ||
        password.length <= 7
      ) {
        if (!regexEmail.test(mail)) {
          setMailError("Formato de e-mail invalido");
        }
        if (!mail) {
          setMailError("Faltando dados de e-mail");
        }
        if (password.length <= 7) {
          setPasswordError("Senha deve conter no mín. 8 caracteres");
        }
        if (!password) {
          setPasswordError("Faltando dados de senha");
        }
        return false;
      }

      const response = await api.post("/session/storage", {
        mail: mail,
        password: password,
      });

      if (response.status == 200) {
        setCurrentClient(response.data.user);
        localStorage.setItem("@USER_NAME", response.data.user.name);
        localStorage.setItem("@USER_MAIL", response.data.user.mail);
        localStorage.setItem("@USER_TOKEN", response.data.token);
        localStorage.setItem("ControlReload", 0)
        localStorage.setItem(
          "@USER_PICTURE",
          response.data.user.image != null ? response.data.user.image : false
        );
        navigate("/dashboard", { replace: true });
        return false;
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    setMailError("");
  }, [mail]);

  useEffect(() => {
    setPasswordError("");
  }, [password]);

  const buttonStyle = {
    backgroundColor: theme.colors.colorPrimary,
    width: "191px",
    height: "45px",
    borderRadius: "50px",
    border: "none",
    color: "#fff",
  };

  return (
    <div style={{}}>
      <ToastContainer />
      <div
        style={{
          height: "100vh",
          paddingTop: "100px",
          paddingBottom: "50px",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          width: "418px",
          margin: "0 auto",
        }}
      >
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={companyIcon} alt="" style={{ width: "450px" }} />
          </div>
          <h2
            className="textPrimaryLanding"
            style={{ color: theme.colors.colorPrimary }}
          >
            Dados de acesso
          </h2>
        </div>
        <Input
          name="E-mail"
          error={mailError}
          onChange={(e) => setMail(e.target.value)}
          value={mail}
          placeholder="Insira seu e-mail"
          type={"text"}
        />
        <Input
          name="Senha"
          error={paswordError}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          placeholder="Insira sua senha"
          type={"password"}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "418px",
          }}
        >
          <button
            style={buttonStyle}
            onClick={() => {
              getLogin();
            }}
          >
            Entrar
          </button>

          <p
            style={{ marginTop: "15px", cursor: "pointer" }}
            onClick={() => {
              window.location.href = "/lostpassword";
            }}
          >
            Esqueceu a senha?
          </p>
        </div>
        <div />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <img
            src={PowerBi}
            style={{
              width: "450px",
            }}
            alt="logo power bi"
          />
        </div>
      </div>
    </div>
  );
};

export default Landing;
