import React, { useState } from "react";
import ThowColumLayoutLogin from "../../layouts/ThowColumLayout";
import lostpasswordIcon from "../../images/svg/lostpassword.svg";
import companyIcon from "../../logo.svg";
import email from "../../images/svg/email.svg";

import "react-toastify/dist/ReactToastify.css";
import { theme } from "../../Global/styles/theme";
import api from "../../services/api";
import { toast } from "react-toastify";

const SentEmail = () => {
  const buttonStyle1 = {
    backgroundColor: "white",
    width: "250px",
    height: "45px",
    borderRadius: "50px",
    border: "1px solid black",
  };

  const buttonStyle2 = {
    backgroundColor: theme.colors.colorPrimary,
    width: "250px",
    height: "45px",
    borderRadius: "50px",
    border: "none",
    borderColor: "#000",
    color: "white",
    marginBottom: "10px",
  };

  const onSubmitSendMail = async () => {
    if (!localStorage.getItem("@PLAY_TELEMATICS_MAIL:")) {
      return toast.error("Preencha o campo de e-mail", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    const data = {
      mail: localStorage.getItem("@PLAY_TELEMATICS_MAIL:"),
    };

    try {
      const response = await api.post("send/email", data);

      toast.info("E-mail reenviado", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      localStorage.getItem("@PLAY_TELEMATICS_MAIL:");
    } catch (error) {
      toast.error(error.response.data.error, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <ThowColumLayoutLogin
      page={"Landing"}
      colum1={
        <div style={{ position: "fixed" }}>
          <img src={lostpasswordIcon} style={{ height: "100vh" }} alt="" />
        </div>
      }
      colum2={
        <div
          style={{
            height: "100vh",
            paddingTop: "100px",
            paddingBottom: "50px",
            width: "418px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img src={companyIcon} alt="" style={{ width: "235px" }} />
            <h2
              className="textPrimaryLanding"
              style={{ color: theme.colors.colorPrimary, textAlign: "center" }}
            >
              E-mail enviado!
            </h2>
            <p
              className="textPrimaryLabelInput"
              style={{ textAlign: "center" }}
            >
              Um link de recuperação de senha foi enviado para o e-mail{" "}
              {localStorage.getItem("@PLAY_TELEMATICS_MAIL:")}
            </p>
          </div>
          <img
            src={email}
            alt=""
            style={{ width: "250px", height: "250px", marginTop: "-30px" }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "418px",
              flexDirection: "column",
              marginTop: "35px",
            }}
          >
            <button
              style={buttonStyle2}
              onClick={() => {
                window.location.href = "/";
              }}
            >
              Entendi
            </button>
            <button style={buttonStyle1} onClick={onSubmitSendMail}>
              Reenviar e-mail
            </button>
          </div>
          <div style={{ paddingBottom: "100px" }} />
        </div>
      }
    ></ThowColumLayoutLogin>
  );
};

export default SentEmail;
