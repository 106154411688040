import React, { useEffect } from "react";

import ThowColumLayoutInventary from "../../layouts/ThowColumLayoutInventary";
import Sidebar from '../../components/Sidebar'
import HeaderDashboard from "../../components/HeaderDashboard";

import { useGlobal } from "../../context/UserContext";
import { theme } from "../../Global/styles/theme"
import { useNavigate } from "react-router-dom";


const Embedded = () => {
    const { embeddedSelected } = useGlobal();
    const navigate = useNavigate();

    useEffect(() => {
        if (!embeddedSelected) {
            navigate("/dashboard", { replace: true });
        }
    }, [embeddedSelected])


    return (
        <ThowColumLayoutInventary
            colum1={<Sidebar page={"Dashboard"} />}
            colum2={
                <div style={{ padding: "0 20px", height: "100%", width: "80%", margin: "0 auto" }}>
                    <div style={{ paddingTop: "2.68rem", paddingBottom: "10px", }}>
                        <HeaderDashboard name={embeddedSelected.title} />
                    </div>

                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "0 auto", border: "1px solid #d7d7d7", borderRadius: "12px", height: "100%" }}>
                        <iframe title={embeddedSelected.title} width="95%" height="90%" src={embeddedSelected.link} frameborder="1" allowFullScreen="true"></iframe>
                    </div>
                    <div style={{ height: "100px" }} />
                </div>
            }
        >
        </ThowColumLayoutInventary >
    )

}

export default Embedded;