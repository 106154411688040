import React, { useState, useEffect } from "react";

import ThowColumLayoutLogin from "../../layouts/ThowColumLayoutLogin";
import forgotPasswordBackgrond from "../../images/svg/forgotPasswordBackgrond.svg";
import companyIcon from "../../images/svg/companyIcon.svg";
import jwt_decode from "jwt-decode";

import Button from "../../components/Button";
import api from "../../services/api";
import Input from "../../components/Input";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState();
  const navigate = useNavigate();
  const token = window.location.pathname.split("/")[3];

  /* MESSAGE ERROS */

  const [mailError, setMailError] = useState();
  const [paswordError, setPasswordError] = useState();

  /* BTN STATE */

  const [btnState, setBtnState] = useState();

  const updatePassword = async () => {
    const id = window.location.pathname.split("/")[3];

    if (!password || !confirmPassword) {
      return toast.error("Preencha todos os campos!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (password !== confirmPassword) {
      return toast.error("Senhas não conferem", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    const data = {
      password: password,
    };

    const token = window.location.pathname.split("/")[3];

    try {
      // await api.post("/token/verify", { token: token });

      const decoded = jwt_decode(token);
      const { id } = decoded;

      try {
        await api.patch(`/client/${id}`, data);

        toast.success("Senhas atualizada com sucesso!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setTimeout(() => {
          navigate("/");
        }, 2000);
      } catch (error) {
        const message = error.response.data.message;
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      const message = error.response.data.message;
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      if (error.response.status === 401) {
        setTimeout(() => {
          toast.error(
            "Link expirado. Por favor, solicite a troca de senha novamente.",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          navigate("/");
        }, 2000);
      }
    }
  };

  const container = {
    width: "418px",
  };

  const boxFooterButton = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const boxIconMail = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "3.25rem",
  };

  const containerLogo = {
    width: "418px",
    display: "flex",
    justifyContent: "start",
    marginBottom: "30px",
  };

  const containerFigure = {
    height: "100vh",
    width: "85%",
    padding: "90px 80px",
    backgroundColor: "#F4F4F4",
  };

  const image = {
    height: "100%",
  };

  const containerColum2 = {
    paddingTop: "9rem",
  };

  return (
    <ThowColumLayoutLogin
      page={"ResetPassword"}
      colum1={
        <div style={containerFigure}>
          <img src={forgotPasswordBackgrond} style={image} />
        </div>
      }
      colum2={
        <div style={containerColum2}>
          <div style={containerLogo}>
            <img src={companyIcon} />
          </div>

          <div style={container}>
            <h2 className="textPrimaryResetPassword">Crie uma nova senha</h2>

            <p className="textDescriptionResetPassword">
              Preencha os campos abaixo com a nova senha que deseja cadastrar
            </p>

            <Input
              name="Nova senha"
              error={paswordError}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Insira sua senha"
              type={"password"}
            />

            <Input
              name="Confirmação de nova senha"
              error={paswordError}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Insira a confirmação da sua senha"
              type={"password"}
            />

            <div style={boxFooterButton}>
              <Button
                width="202px"
                height="48px"
                background="#000"
                borderRadius="60px"
                marginTop="0"
                name="Criar senha"
                border="none"
                disabled={btnState}
                onClick={() => updatePassword()}
              />

              <p
                onClick={() => navigate("/")}
                style={{
                  cursor: "pointer",
                }}
                className="textForgotPassword"
              >
                Lembrou da senha?
              </p>
            </div>
          </div>
        </div>
      }
    ></ThowColumLayoutLogin>
  );
};

export default ResetPassword;
