export const theme = {
  colors: {
    colorPrimary: "#000",
    background: "#ffffff",
    secondaryBackground: "#FAF9F9",
    border: "#E8E8E8",
    secondaryBorder: "#D7D7D7",
    thirdBorder: "#F1EBEA",
    backgroundDashboard: "",
    negative: "",
    white: "#ffffff",
    grey0: "#D7D7D7",
    grey1: "#CFCFCF",
    grey2: "#878888",
    grey3: "#A0A0A0",
    grey4: "#E8E8E8",
    grey5: "#D8D8D8",
    grey6: "#787486",
    grey7: "#E5E5E5",
    grey8: "#E4E4EF",
    grey9: "#808080",
    black: "#000000",
    black1: "#191D1E",
    black2: "#1A1A1A",
    hover: "",
    yello: "#F4DC01",
    yellow1: "#FFF7AA",
    yellow2: "#C6B200",
    green0: "#E0FFF0",
    green1: "#00B386",
    red0: "#ffbaba",
    red1: "#ff5252",
    red2: "#FFE1E1",
    red3: "#EA0000",
    blue0: "#E9F4FF",
    blue1: "#05028C",
  },
  fonts: {
    fontPoppinsTitle: "Poppins_500Medium",
    fontPoppinsContent: "Poppins_400Regular",
    fontPoppinsTitleBold: "Poppins_600SemiBold",
  },
};
