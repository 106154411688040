import React, { useState, useEffect, useRef } from "react";

import ThowColumLayoutInventary from "../../layouts/ThowColumLayoutInventary";
import Sidebar from "../../components/Sidebar";
import HeaderDashboard from "../../components/HeaderDashboard";

import app from "../../firebase/database"
import { toast, ToastContainer } from "react-toastify";
import api from "../../services/api";
import Input from "../../components/Input";
import profile from "../../images/svg/profile.svg";
import { theme } from "../../Global/styles/theme";
import { useGlobal } from "../../context/UserContext";


const Profile = () => {
    const hiddenFileInput = useRef(null);
    const { setUpdateUser } = useGlobal()

    const [name, setName] = useState("");
    const [mail, setMail] = useState("");
    const [picture, setPicture] = useState("");
    const [password, setPassword] = useState("");

    /* ERROR MESSAGES */
    const [nameError, setNameError] = useState(false);
    const [mailError, setMailError] = useState(false);
    const [paswordError, setPasswordError] = useState(false);

    const getProfile = async () => {
        const response = await api.get("/client/profile/one");

        if (response.status === 200) {
            setPicture(response.data.image);
            setMail(response.data.mail);
            setName(response.data.name);
            localStorage.setItem("@USER_PICTURE", response.data.image);
            localStorage.setItem("@USER_NAME", response.data.name)
            localStorage.setItem("@USER_MAIL", response.data.mail)
        }
    };


    useEffect(() => {
        if (!localStorage.getItem("@USER_TOKEN")) {
            localStorage.clear()
            window.location.href = "/"
        }
        getProfile();
    }, [])


    const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const handleClick = async () => {
        if (!regexEmail.test(mail) || (password && password.length < 8)) {
            if (!regexEmail.test(mail)) {
                setMailError(true);
            }

            if (password && password.length < 8) {
                setPasswordError(true);
            }

            return false;
        }

        const response = await api.get("/client/profile/one");

        if (password) {
            const client = await api.patch(`/client/${response.data.id}`, { name, mail, password, image: picture });
            localStorage.setItem("@USER_PICTURE", client.data.image)
            localStorage.setItem("@USER_NAME", client.data.name)
            localStorage.setItem("@USER_MAIL", client.data.mail)
            toast.success(`Atualizado com sucesso!`, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setUpdateUser(prevState => !prevState)
            return false;
        } else {
            const client = await api.patch(`/client/${response.data.id}`, { name, mail, image: picture });
            localStorage.setItem("@USER_PICTURE", client.data.image)
            localStorage.setItem("@USER_NAME", client.data.name)
            localStorage.setItem("@USER_MAIL", client.data.mail)
            toast.success(`Atualizado com sucesso!`, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setUpdateUser(prevState => !prevState)
            return false;
        }

    };


    const loadImage = async (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        await app
            .storage()
            .ref(`/profile/${file.name}`)
            .put(file)
            .then(() => {
                app.storage()
                    .ref(`/profile/${file.name}`)
                    .getDownloadURL()
                    .then(async (url) => {
                        setPicture(url);
                    });
            });
    };

    useEffect(() => {
        setNameError(false);
    }, [name]);

    useEffect(() => {
        setMailError(false);
    }, [mail]);

    useEffect(() => {
        setPasswordError(false);
    }, [password]);


    const buttonStyle = {
        backgroundColor: theme.colors.colorPrimary,
        width: "40%",
        height: "45px",
        borderRadius: "50px",
        border: "none",
        color: "#fff",
    };

    return (
        <>
            <ToastContainer />
            <ThowColumLayoutInventary
                colum1={<Sidebar page={"profile"} />}
                colum2={
                    <div style={{ paddingRight: "4rem", paddingLeft: "4rem", paddingTop: "2.68rem", paddingBottom: "100px" }}>
                        <HeaderDashboard name="Minha conta" />
                        <div style={{ width: "60vw", border: "1px solid", borderColor: theme.colors.border, borderRadius: "10px", margin: "0 auto", paddingTop: "20px", paddingBottom: "30px" }}>
                            <div style={{ marginTop: "1.32rem", marginBottom: "2rem" }}>
                                <label htmlFor='file' style={{ display: "flex", justifyContent: "center" }}>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                        {picture != undefined
                                            ?
                                            <img src={picture} style={{ width: "120px", height: "120px", borderRadius: "100%", objectFit: "cover" }} alt='' />
                                            :
                                            <img src={profile} style={{ width: "120px", height: "120px", borderRadius: "100%", objectFit: "cover" }} alt='' />
                                        }
                                        <div style={{}} onClick={() => { hiddenFileInput.current.click() }}>
                                            <input type="file" onChange={(text) => { loadImage(text.target.files[0]) }} ref={hiddenFileInput} style={{ display: "none" }} accept="image/png, image/jpeg" />
                                            <div style={{ position: "relative" }}>
                                                <div style={{ position: "absolute", left: "15px", top: "-30px" }}>
                                                    <svg width="39" height="39" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="1" y="1" width="26.571" height="26.791" rx="13.286" fill="#000" stroke="#fff" strokeWidth="2" />
                                                        <path d="M17.86 10.793a.977.977 0 0 1-.864-.534l-.428-.87c-.274-.546-.989-.995-1.596-.995H13.61c-.613 0-1.327.45-1.6.995l-.43.87a.977.977 0 0 1-.863.534 2.244 2.244 0 0 0-2.232 2.393l.31 4.955c.071 1.235.732 2.249 2.375 2.249h6.238c1.643 0 2.297-1.014 2.375-2.25l.31-4.954a2.244 2.244 0 0 0-2.233-2.393Zm-4.465.75h1.786a.451.451 0 0 1 0 .9h-1.786a.451.451 0 0 1-.447-.45c0-.246.203-.45.447-.45Zm.893 6.52a2.021 2.021 0 0 1-2.012-2.028c0-1.121.899-2.027 2.012-2.027s2.012.906 2.012 2.027a2.021 2.021 0 0 1-2.012 2.028Z" fill="#fff" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>


                            <div style={{ paddingLeft: "20px", paddingRight: "20px", width: "100%" }}>
                                <div style={{ display: "flex", justifyContent: "space-around", height: "110px", width: "100%" }}>
                                    <div style={{ position: "relative" }}>
                                        <Input onChange={(e) => setName(e.target.value)} value={name} color="black" width="20vw" height="45px" name="Nome*" placeholder={"Insira o nome"} borderColor={nameError ? theme.colors.red3 : theme.colors.border} />
                                    </div>

                                    <div style={{ position: "relative" }}>
                                        <Input onChange={(e) => setMail(e.target.value)} value={mail} color="black" width="20vw" height="45px" name="E-mail de acesso*" placeholder={"Insira o e-mail"} borderColor={mailError ? theme.colors.red3 : theme.colors.border} />
                                    </div>
                                </div>

                                <div style={{ display: "flex", justifyContent: "space-around", height: "110px", width: "100%" }}>
                                    <div style={{ position: "relative" }}>
                                        <Input onChange={(e) => setPassword(e.target.value)} value={password} color="black" width="20vw" height="45px" name="Senha de acesso" placeholder="Mudar senha" borderColor={paswordError ? theme.colors.red3 : theme.colors.border} error={paswordError} type={"password"} />
                                    </div>
                                    <div style={{ position: "relative" }}>
                                        <div style={{ width: "20vw", height: "45px" }} />
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                                <button type="button" style={buttonStyle} onClick={() => { handleClick() }}>
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                }
            >
            </ThowColumLayoutInventary>
        </>
    );
}

export default Profile;
