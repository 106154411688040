import React from 'react'
import HeaderDashboard from '../components/HeaderDashboard'
import { theme } from '../Global/styles/theme'
const ThowColumLayoutInventary = ({colum1,colum2,background,page,name,}) => {

    const container = {
        "background": theme.colors.background,
        "height":"100vh",
        "display":"grid",
        "gridTemplateColumns": "229px 1fr "
    }

    const colum1Layout = {
        gridColumnStart: '1',
        gridColumnEnd: '2',
    }

    const colum2Layout = {
        gridColumnStart: '2',
        gridColumnEnd: '2',
        
    }
    


    return(
        
        <>

        <div style={container}>
        <div style={colum1Layout}>
     
        {colum1}
        </div>

        <div  style={colum2Layout}>
        {colum2}
        </div> 
        
                 

        </div>

        </>
    )

}

export default ThowColumLayoutInventary