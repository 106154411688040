import React, { useEffect, useState } from 'react'
import marcelaIcon from '../../images/svg/marcelaIcon.svg'
import backIcon from '../../images/svg/backIcon.svg'
import { BigHead } from '@bigheads/core'
import profile from "../../images/svg/profile.svg"
import api from '../../services/api'
import { useGlobal } from '../../context/UserContext'


const HeaderDashboard = ({ name, page, arrow }) => {
    const [userName, setUserName] = useState("")
    const [userImage, setUserImage] = useState("")
    const { updateUser } = useGlobal()


    const headerStyle = {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        zIndex: "1",
        marginBottom: "2.25rem"
    }
    const userInfo = {
        display: "flex",
        alignItems: "center",
        paddingLeft: "1.1rem",
        justifyContent: "space-between",
        paddingRight: "1.1rem",
        width: "234px",
        background: "white",
        height: "45px",
        border: "1.9px solid #d7d7d7",
        borderRadius: "60px"
    }

    const exit = async () => {
        localStorage.clear();
        localStorage.removeItem("@MAIL")
        window.location.href = "/"
    }

    const getUser = async () => {
        const client = await api.get("/client/profile/one");
        localStorage.setItem("@USER_PICTURE", client.data.image)
        localStorage.setItem("@USER_NAME", client.data.name)
        localStorage.setItem("@USER_MAIL", client.data.mail)
        setUserName(client.data.name)
        setUserImage(client.data.image)
    }

    useEffect(() => {
        getUser()
    }, [updateUser])

    return (

        <div style={headerStyle}>
            {arrow == true
                ?
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img alt="" src={backIcon} style={{ position: "relative", top: "-8px", right: "27px", cursor: "pointer" }} onClick={() => window.location.href = `${page}`} />
                    <p className="textPrimaryHeaderDashboard">{name}</p>
                </div>
                :
                <p className="textPrimaryHeaderDashboard">{name}</p>
            }
            <div className="dropdown"   >
                <button style={userInfo} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div style={{ position: "relative", top: "-2px", right: "13px" }}>

                        <div style={{ width: "44px", height: "44px", marginTop: "-0.2rem" }}>
                            {userImage ?
                                <img src={userImage} alt='' style={{ width: "35px", height: "35px", borderRadius: "20px", marginTop: "8px", objectFit: "cover" }} />
                                :
                                <img src={profile} alt='' style={{ width: "35px", height: "35px", borderRadius: "20px", marginTop: "8px", objectFit: "cover" }} />
                            }
                        </div>
                    </div>
                    <p className="textClientInfoHeader">{userName}</p>

                    <svg width="8" height="8" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.182668 1.78582C0.0688405 1.57763 0.0688405 1.32582 0.182668 1.11763C0.446809 0.634515 1.14065 0.634515 1.40479 1.11763L2.41937 2.97328C3.12207 4.25852 4.96793 4.25852 5.67064 2.97327L6.68521 1.11763C6.94935 0.634515 7.64319 0.634515 7.90733 1.11763C8.02116 1.32582 8.02116 1.57763 7.90733 1.78582L6.67725 4.03563C5.53942 6.11672 2.55058 6.11672 1.41275 4.03563L0.182668 1.78582Z" fill="black" />
                    </svg>
                </button>


                <div className="dropdown-menu" style={{ width: "235px" }} aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item" onClick={() => exit()} href="#">Sair</a>
                </div>
            </div>



        </div>


    )

}

export default HeaderDashboard