import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

/* Pages */
import Landing from "../pages/Landing";
import LostPassword from "../pages/LostPassword";
import SentEmail from "../pages/SentEmail";
import Dashboard from "../pages/Dashboard";
import Access from "../pages/Access";
import Profile from "../pages/Profile";
import api from "../services/api";
import ResetPassword from "../pages/ResetPassword";
import Embedded from "../pages/Embedded";

const AppStack = () => {
  const [typeUser, setTypeUser] = useState("");

  const getData = async () => {
    try {
      const response = await api.get("/client/profile/one");
      if (response.status === 200) {
        setTypeUser(response.data.type);
      }

    } catch (error) {
      console.log("Error 404")
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/lostpassword" element={<LostPassword />} />
        <Route path="/send/email" element={<SentEmail />} />
        <Route path="/reset/password/:id" element={<ResetPassword />} />

        <Route path="/dashboard" element={<Dashboard />} />
        {typeUser && <Route path="/dashboard/embedded" element={<Embedded />} />}
        {typeUser === "Administrativo" && (<Route path="/access" element={<Access />} />)}
        {typeUser && (<Route path="/profile" element={<Profile />} />)}
      </Routes>
    </Router>
  );
};

export default AppStack;
