import React from 'react';
import { theme } from '../../Global/styles/theme';
import { useNavigate } from 'react-router-dom';
import { useGlobal } from "../../context/UserContext"

const CardBI = ({ card, setSelectPowerBI, setShowModal, typeUser }) => {
    const navigate = useNavigate();
    const { embeddedSelected, setEmbeddedSelected } = useGlobal();


    const styleCard = {
        width: "32%",
        height: "290px",
        paddingTop: "13px",
        paddingLeft: "16px",
        paddingRight: "16px",
        borderRadius: "15px",
        background: "white",
        border: "1px solid #D7D7D7",
        marginBottom: "20px",
    }

    const buttonStyle = {
        backgroundColor: theme.colors.colorPrimary,
        width: "100%",
        height: "45px",
        borderRadius: "50px",
        border: "none",
        color: "#fff",
    }

    const styleImage = {
        width: "100%",
        height: "150px",
        borderRadius: "10px",
        background: "white",
        border: "1px solid #D7D7D7",
        marginBottom: "16px",
        objectFit: "cover"
    }

    const headerCard = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
    }


    return (
        <div style={styleCard}>
            <div style={headerCard}>
                <p className='textTitlePowerBI' style={{ width: "95%", height: "24px", overflow: "hidden" }}>{card.title}</p>
                {typeUser === "Administrativo" &&
                    <button style={{ all: "unset", cursor: "pointer", marginBottom: "20px" }} onClick={() => { setSelectPowerBI(card); setShowModal(true); }}>
                        <svg width="5" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="1.938" cy="2.703" fill="#000" r="1.938" />
                            <circle cx="1.942" cy="8.516" fill="#000" r="1.938" />
                            <circle cx="1.942" cy="15.297" fill="#000" r="1.938" />
                        </svg>
                    </button>
                }
            </div>

            <img style={styleImage} src={card.image} alt='imagem do Power BI' />
            <button type="button" style={buttonStyle} onClick={() => { navigate("/dashboard/embedded"); setEmbeddedSelected(card); }}>Acessar BI</button>
        </div>
    )
}

export default CardBI