import React, { useState, useEffect } from "react";
import Select from "react-dropdown-select";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";

import { theme } from "../../../Global/styles/theme";
import api from "../../../services/api";

import close from "../../../images/svg/close.svg";

const ModalPowerBi = ({
    setShowModal,
    showModal,
    data,
    setSelectPowerBI,
    setUpdateDashboards,
}) => {

    const [accessUser, setAccessUser] = useState([]);
    const [allAccess, setAllAccess] = useState(false);
    const [options, setOptions] = useState([]);


    const buttonStyle = {
        backgroundColor: theme.colors.colorPrimary,
        width: "95px",
        height: "40px",
        borderRadius: "50px",
        border: "none",
        color: "#fff",
    };

    const styleSelect = {
        display: "flex",
        alignItems: "center",
        borderRadius: "12px",
        minHeight: "50px",
        border: `2px solid ${theme.colors.border}`,
        background: "#F9F9F9",
        width: "100%",
        paddingLeft: "10px",
        paddingRight: "10px",
        marginRight: "15px",
        backgroundColor: theme.colors.white,
    };


    const handleEditOrAdd = async () => {
        try {
            const currentClient = await api.get("/client/profile/one");

            const requestData = {
                accessUser,
                business: currentClient.data.business,
                general_access: allAccess,
            };

            if (data.id) {
                const response = await api.patch("/dashboard/edit", {
                    requestData,
                    id: data.id,
                });
                if (response.status === 200) {
                    setUpdateDashboards((prevState) => !prevState);
                    toast.success("Atualizado com sucesso!", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                setSelectPowerBI({});
                setShowModal(false);
                return false;
            }
        } catch (error) {
            console.log("Erro no envio de dados pro banco!")
        }
    };

    useEffect(() => {
        getAllClientsAccess();
    }, [data]);

    const getAllClientsAccess = async () => {
        try {
            if (data.id) {
                const response = await api.post(
                    "/dashboard/getAllClientsByDashboardId",
                    { id: data.id }
                );
                const formattedArray = response.data.map((e) => {
                    const jsxElement = (
                        <div className="country-option">
                            <img src={e.image} alt={e.name} style={{ width: "30px", height: "30px", objectFit: "cover", backgroundColor: "white", border: "1px solid #000", borderRadius: "50%", marginRight: "15px" }} />
                            <span>{e.name}</span>
                        </div>
                    );
                    return { name: jsxElement, realName: e.name, id: e.id, image: e.image };
                });

                setAccessUser(formattedArray);
            }
        } catch (error) {
            console.log("Erro na busca por dados!")
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const currentClient = await api.get("/client/profile/one");
        const businessClient = currentClient.data.business;
        const allClients = await api.get("/client/BI");
        setOptions(allClients.data.filter((e) => e.business === businessClient).map((e) => ({
            name:
                <div className="country-option">
                    <img src={e.image} alt={e.name} style={{ width: "30px", height: "30px", objectFit: "cover", backgroundColor: "white", border: "1px solid #000", borderRadius: "50%", marginRight: "15px" }} />
                    <span>{e.name}</span>
                </div>,
            realName: e.name,
            id: e.id
        })));
    };


    return (
        <>
            <ToastContainer />
            <Modal
                dialogClassName="modal-filter-edit"
                show={showModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
            >
                <Modal.Header style={{}}>
                    <div
                        style={{
                            display: "flex",
                            height: "30px",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div
                                    onClick={() => {
                                        setShowModal(false);
                                        setSelectPowerBI(false);
                                    }}
                                    style={{
                                        cursor: "pointer",
                                        width: "20px",
                                        textAlign: "center",
                                        marginRight: "20px",
                                    }}
                                >
                                    <img src={close} alt="icone de fechar" />
                                </div>
                                <p className="textHeaderModalUser">
                                    Editar acesso BI
                                </p>
                            </div>
                            <button style={buttonStyle} onClick={handleEditOrAdd}>
                                Editar
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ display: "flex", flexDirection: "column", paddingLeft: "15px", paddingRight: "15px", height: " 300px" }}>
                    <div>
                        <p style={{ marginBottom: "10px" }}>Quem tem acesso ao Power BI</p>
                        <Select
                            color="#000"
                            placeholder="Selecione"
                            dropdownHeight={"150px"}
                            searchable={true}
                            searchBy="realName"
                            keepSelectedInList={true}
                            options={options}
                            multi={true}
                            labelField="name"
                            valueField="id"
                            isSearchable={true}
                            onChange={(value) => {
                                setAccessUser(value);
                            }}
                            style={styleSelect}
                            values={accessUser}
                        />
                    </div>

                    <div style={{ marginTop: "20px" }}>
                        <>
                            {!allAccess ?
                                (
                                    <div onClick={() => setAllAccess((prevState) => !prevState)} style={{ cursor: "pointer", display: "flex" }}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x=".5" y=".5" width="19" height="19" rx="4.5" fill="#fff" stroke="#000" />
                                            <path d="M8.745 13.136a.908.908 0 0 1-.644-.266l-1.944-1.944a.536.536 0 0 1 .758-.758l1.83 1.83 4.84-4.841a.536.536 0 0 1 .758.758L9.388 12.87a.908.908 0 0 1-.643.266Z" fill="#fff" />
                                        </svg>
                                        <p style={{ marginLeft: "10px", alignItems: "center" }}>
                                            Liberar acesso a todos usuários
                                        </p>
                                    </div>
                                )
                                :
                                (
                                    <div onClick={() => setAllAccess((prevState) => !prevState)} style={{ cursor: "pointer", display: "flex" }}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x=".5" y=".5" width="19" height="19" rx="4.5" fill="#000" stroke="#000" />
                                            <path d="M8.745 13.136a.908.908 0 0 1-.644-.266l-1.944-1.944a.536.536 0 0 1 .758-.758l1.83 1.83 4.84-4.841a.536.536 0 0 1 .758.758L9.388 12.87a.908.908 0 0 1-.643.266Z" fill="#fff" />
                                        </svg>
                                        <p style={{ marginLeft: "10px", alignItems: "center" }}>
                                            Liberar acesso a todos usuários
                                        </p>
                                    </div>
                                )
                            }
                        </>
                    </div>
                    <div style={{ marginBottom: "100px" }} />
                </Modal.Body >
            </Modal >
        </>
    );
};

export default ModalPowerBi;
