import React from "react";
import { useGlobal } from "../../context/UserContext";

const InputSelectFilterManagerUsers = ({ uniq }) => {
  const { setFilterName, filterName, onGetFilterClient, onGetClient } =
    useGlobal();

  const input = {
    width: "290px",
    height: "45px",
    borderRadius: "60px",
    border: "1px solid #D7D7D7",
    position: "relative",
    paddingLeft: "50px",
    outline: "none",
  };

  return (
    <div style={{ position: "relative" }}>
      <input
        className="inputSelectFilterManager"
        type="text"
        style={input}
        placeholder="Pesquisar"
        onBlur={() => {
          if (String(filterName).length > 0) {
            onGetFilterClient(1);
          } else {
            onGetClient(1);
          }
        }}
        value={filterName}
        onChange={(text) => setFilterName(text.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onGetFilterClient(1);
          }
        }}
      />
      <div style={{ position: "absolute", top: "7px", left: "10px" }}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 2C15.968 2 20 6.032 20 11C20 15.968 15.968 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2ZM11 18C14.867 18 18 14.867 18 11C18 7.132 14.867 4 11 4C7.132 4 4 7.132 4 11C4 14.867 7.132 18 11 18ZM19.485 18.071L22.314 20.899L20.899 22.314L18.071 19.485L19.485 18.071Z"
            fill="#000"
          />
        </svg>
      </div>
    </div>
  );
};

export default InputSelectFilterManagerUsers;
