import React, { useContext } from "react";
import profile from "../../images/svg/profile.svg";
import { useGlobal } from "../../context/UserContext";

const CardTableUsers = ({ card, setSelectUser, setShowModal }) => {
  const { setClientId } = useGlobal();

  const styleBtn = {
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
  };


  return (
    <tbody style={{ position: "relative", top: "20px" }}>
      <tr style={{ position: "relative", top: "20px", border: "none" }}>
        <td style={{ borderRight: "none" }}>
          {/* <select
            style={{
              appearance: "none",
              width: "15px",
              height: "15px",
              borderRadius: "3px",
              position: "relative",
              top: "6px",
            }}
          /> */}
        </td>
        <td style={{ borderRight: "none" }}>
          <div style={{ display: "flex", position: "relative", top: "9px" }}>
            {card.image == undefined ? (
              <div
                style={{
                  width: "45px",
                  height: "35px",
                  position: "relative",
                  top: "-17px",
                  left: "-5px",
                  borderRadius: "100%",
                }}
              >
                <img
                  src={profile}
                  style={{
                    width: "35px",
                    height: "35px",
                    position: "relative",
                    top: "10px",
                    left: "0",
                    borderRadius: "100px",
                    border: "1px solid #000",
                    objectFit: "cover",
                  }}
                  alt=""
                />
              </div>
            ) : (
              <div style={{ display: "flex" }}>
                <img
                  src={card.image}
                  style={{
                    width: "35px",
                    height: "35px",
                    position: "relative",
                    top: "-8px",
                    left: "-5px",
                    borderRadius: "100px",
                    border: "1px solid #000",
                    objectFit: "cover",
                  }}
                  alt=""
                />
              </div>
            )}
            <p
              style={{
                borderRight: "none",
                color: "black",
                position: "relative",
                left: "10px",
              }}
            >
              {card.name}
            </p>
          </div>
        </td>
        <td style={{ borderRight: "none", color: "black" }}>{card.mail}</td>
        <td style={{ borderRight: "none", color: "black" }}>{card.type}</td>
        <td
          onClick={() => {
            setClientId(card.id);
            setSelectUser(card);
            setShowModal(true);
          }}
        >
          <button className="textBtnUsers" style={styleBtn}>
            <svg
              width="5"
              height="19"
              viewBox="0 0 5 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="2.17315" cy="2.43731" r="2.17315" fill="black" />
              <circle cx="2.17705" cy="8.95684" r="2.17315" fill="black" />
              <circle cx="2.17705" cy="16.5628" r="2.17315" fill="black" />
            </svg>
          </button>
        </td>
      </tr>
    </tbody>
  );
};

export default CardTableUsers;
