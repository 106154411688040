import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import close from "../../images/svg/close.svg";
import Input from "../Input";
import { useGlobal } from "../../context/UserContext";
import { ToastContainer } from "react-toastify";
import CardTableUsers from "../CardTableUsers";
import profile from "../../images/svg/profile.svg";
import app from "../../firebase/database";
import { theme } from "../../Global/styles/theme";
import Pagination from "../Pagination";
import api from "../../services/api";

const TableListUser = ({
  modalEdit,
  setModalEdit,
  selectUser,
  setSelectUser,
}) => {
  const {
    onGetClient,
    totalPage,
    users,
    onDestroyClient,
    onUpdateClient,
    filterName,
    onGetFilterClient,
  } = useGlobal();

  const hiddenFileInput = useRef(null);

  const [currentPage, setCurrentPage] = useState(1);

  /* ERROR MESSAGES */
  const [nameError, setNameError] = useState(false);
  const [mailError, setMailError] = useState(false);
  const [paswordError, setPasswordError] = useState(false);
  const [permissionError, setPermissionError] = useState(false);

  /* MODAL STATE */
  const [showModal, setShowModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  /* USER INFO */
  const [idUser, setIdUser] = useState();
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [picture, setPicture] = useState("");
  const [password, setPassword] = useState("");
  const [permission, setPermission] = useState("");

  /* UPDATE DATA */
  const [updateResponse, setUpdateResponse] = useState(0);

  const btnStyleModal = {
    border: "none",
    outline: "none",
    background: "#000",
    borderRadius: "50px",
    width: "101px",
    height: "36px",
    color: "#fff",
  };

  const btnStyleRemove = {
    width: "82px",
    height: "37px",
    borderRadius: "50px",
    border: "none",
    background: "#FFE1E1",
  };

  const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const handleEdit = async () => {
    if (
      !name ||
      !mail ||
      !regexEmail.test(mail) ||
      !permission ||
      (password && password.length < 8)
    ) {
      if (!name) {
        setNameError(true);
      }

      if (!mail || !regexEmail.test(mail)) {
        setMailError(true);
      }

      if (password && password.length < 8) {
        setPasswordError(true);
      }

      if (!permission) {
        setPermissionError(true);
      }

      return false;
    }

    if (password) {
      const data = {
        image: picture,
        name,
        mail,
        type: permission,
        password,
      };

      onUpdateClient(data);
      setModalEdit(false);
      setSelectUser({});
      setName("");
      setMail("");
      setPassword("");
      setPicture("");
      setPermission("");
      setUpdateResponse((prevState) => prevState + 1);
      return false;
    }

    const data = {
      image: picture,
      name,
      mail,
      type: permission,
    };

    onUpdateClient(data);

    setModalEdit(false);
    setSelectUser({});
    setName("");
    setMail("");
    setPassword("");
    setPicture("");
    setPermission("");
    setUpdateResponse((prevState) => prevState + 1);
  };

  const handleCreate = async () => {
    if (
      !name ||
      !mail ||
      !regexEmail.test(mail) ||
      !permission ||
      (password && password.length < 8)
    ) {
      if (!name) {
        setNameError(true);
      }

      if (!mail || !regexEmail.test(mail)) {
        setMailError(true);
      }

      if (password && password.length < 8) {
        setPasswordError(true);
      }

      if (!permission) {
        setPermissionError(true);
      }

      return false;
    }

    try {
      const response = await api.get("/client/profile/one");
      const data = {
        image: picture,
        name,
        mail,
        type: permission,
        password,
        business: response.data.business,
      };
      await api.post("/client", data);
      setModalEdit(false);
      onGetClient(1);
    } catch (error) {
      console.log("Erro na busca por dados!");
    }
  };

  const handleDelete = async () => {
    setModalDelete(false);
    setSelectUser({});
  };

  const handleClose = () => {
    setModalEdit(false);
    setSelectUser({});
    setName("");
    setMail("");
    setPassword("");
    setPicture("");
    setPermission("");
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const loadImage = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    await app
      .storage()
      .ref(`/profile/${file.name}`)
      .put(file)
      .then(() => {
        app
          .storage()
          .ref(`/profile/${file.name}`)
          .getDownloadURL()
          .then(async (url) => {
            setPicture(url);
          });
      });
  };

  useEffect(() => {
    if (modalEdit) {
      setPicture(selectUser.image);
      setName(selectUser.name);
      setMail(selectUser.mail);
      setPermission(selectUser.type);
    }
  }, [modalEdit]);

  useEffect(() => {
    setNameError(false);
  }, [name, modalEdit]);

  useEffect(() => {
    setMailError(false);
  }, [mail, modalEdit]);

  useEffect(() => {
    setPasswordError(false);
  }, [password, modalEdit]);

  useEffect(() => {
    setPermissionError(false);
  }, [permission, modalEdit]);

  useEffect(() => {
    if (String(filterName).length > 0) {
      onGetFilterClient(currentPage);
    } else {
      onGetClient(currentPage);
    }
  }, [updateResponse]);

  useEffect(() => {
    if (String(filterName).length > 0) {
      onGetFilterClient(currentPage);
    } else {
      onGetClient(currentPage);
    }
  }, [currentPage]);

  return (
    <div>
      <ToastContainer />
      <table
        className="border-table"
        style={{ textAlign: "left", width: "100%", marginTop: "2.68rem" }}
      >
        <thead>
          <tr style={{ position: "relative", top: "20px" }}>
            <td style={{ borderRight: "none" }}></td>
            <td style={{ borderRight: "none" }}>Nome do usuário</td>
            <td style={{ borderRight: "none" }}>E-mail de acesso</td>
            <td style={{ borderRight: "none" }}>Permissão</td>
            <td></td>
          </tr>
        </thead>
        {users.length > 0 ? (
          users.map((item) => {
            return (
              <>
                <CardTableUsers
                  key={item.id}
                  setSelectUser={setSelectUser}
                  setShowModal={setShowModal}
                  card={item}
                />
                <div style={{ marginTop: "13px" }} />
              </>
            );
          })
        ) : (
          <></>
        )}
        <div style={{ paddingBottom: "100px" }} />
      </table>
      <Pagination
        totalPages={totalPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />

      {/* MODAL DE ACOES */}
      <Modal
        dialogClassName="modal-filter-option"
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header style={{ marginBottom: "-15px" }}>
          <div
            style={{
              display: "flex",
              height: "30px",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p className="textHeaderModalUser">Ações</p>
            <div
              onClick={() => setShowModal(false)}
              style={{ cursor: "pointer", width: "20px", textAlign: "center" }}
            >
              <img src={close} alt="icone de fechar" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "column",
            height: "240px",
            marginLeft: "-15px",
            marginRight: "-15px",
          }}
        >
          <button
            onClick={() => {
              setModalEdit(true);
              setShowModal(false);
            }}
            style={{
              border: "none",
              backgroundColor: "white",
              color: "black",
              borderBottom: `1px solid ${theme.colors.border}`,
              height: "60px",
            }}
          >
            <b>Editar acesso BI</b>
          </button>
          <button
            onClick={() => {
              setModalDelete(true);
              setShowModal(false);
            }}
            style={{
              border: "none",
              backgroundColor: "white",
              color: "black",
              borderBottom: `1px solid ${theme.colors.border}`,
              height: "60px",
            }}
          >
            <b style={{ color: theme.colors.red3 }}>Excluir acesso BI</b>
          </button>
        </Modal.Body>
      </Modal>

      {/* MODAL DE DELETE */}
      <Modal
        dialogClassName="modal-filter-option"
        show={modalDelete}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header style={{ marginBottom: "-15px" }}>
          <div
            style={{
              display: "flex",
              height: "30px",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "180px",
              }}
            >
              <div
                onClick={() => setModalDelete(false)}
                style={{
                  cursor: "pointer",
                  width: "20px",
                  textAlign: "center",
                }}
              >
                <img src={close} alt="icone de fechar" />
              </div>
              <p className="textHeaderModalUser">Excluir usuário</p>
            </div>
            <button
              style={btnStyleRemove}
              onClick={() => {
                handleDelete();
                onDestroyClient();
              }}
            >
              <b style={{ color: "red", textAlign: "center" }}>Excluir</b>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "column",
            height: "240px",
            paddingLeft: "25px",
            paddingRight: "25px",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <p>
            Tem certeza que deseja excluir o usuário <b>{selectUser.name}</b> ?
          </p>
        </Modal.Body>
      </Modal>

      {/* MODAL DE EDICAO */}
      <Modal
        dialogClassName="modal-filter-table-list-user"
        show={modalEdit}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header>
          <div
            style={{
              display: "flex",
              height: "30px",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              paddingLeft: "26px",
              paddingRight: "26px",
            }}
          >
            <div
              onClick={() => {
                handleClose();
              }}
              style={{ cursor: "pointer", width: "100px" }}
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_0_2887)">
                  <path
                    d="M7.49864 6.3679L12.5016 1.35941C12.8122 1.04847 12.8122 0.544354 12.5016 0.233445C12.1911 -0.0774893 11.6876 -0.0774893 11.377 0.233445L6.37456 5.24246L1.37211 0.233445C1.06155 -0.0774893 0.55804 -0.0774893 0.247506 0.233445C-0.0630274 0.544378 -0.0630522 1.0485 0.247506 1.35941L5.25048 6.3679L0.247506 11.3764C-0.0630522 11.6874 -0.0630522 12.1915 0.247506 12.5024C0.558065 12.8133 1.06158 12.8133 1.37211 12.5024L6.37456 7.49334L11.377 12.5024C11.6876 12.8133 12.1911 12.8133 12.5016 12.5024C12.8122 12.1915 12.8122 11.6873 12.5016 11.3764L7.49864 6.3679Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_0_2887">
                    <rect
                      width="12.72"
                      height="12.7354"
                      fill="white"
                      transform="translate(0.0143433)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <p className="textHeaderModalUser">
              {selectUser.name ? "Editar usuário" : "Novo usuário"}
            </p>

            <button
              className="textBtnFilterManagerModal"
              style={btnStyleModal}
              onClick={selectUser.name ? handleEdit : handleCreate}
            >
              {selectUser.name ? "Editar" : "Criar"}
            </button>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div style={{ marginTop: "1.32rem", marginBottom: "2rem" }}>
            <label
              htmlFor="file"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {picture != undefined ? (
                  <img
                    src={picture}
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "100%",
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                ) : (
                  <img
                    src={profile}
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "100%",
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                )}
                <div
                  style={{}}
                  onClick={() => {
                    hiddenFileInput.current.click();
                  }}
                >
                  <input
                    type="file"
                    onChange={(text) => {
                      loadImage(text.target.files[0]);
                    }}
                    ref={hiddenFileInput}
                    style={{ display: "none" }}
                    accept="image/png, image/jpeg"
                  />
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        left: "15px",
                        top: "-30px",
                      }}
                    >
                      <svg
                        width="39"
                        height="39"
                        viewBox="0 0 29 29"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="1"
                          y="1"
                          width="26.571"
                          height="26.791"
                          rx="13.286"
                          fill="#000"
                          stroke="#fff"
                          strokeWidth="2"
                        />
                        <path
                          d="M17.86 10.793a.977.977 0 0 1-.864-.534l-.428-.87c-.274-.546-.989-.995-1.596-.995H13.61c-.613 0-1.327.45-1.6.995l-.43.87a.977.977 0 0 1-.863.534 2.244 2.244 0 0 0-2.232 2.393l.31 4.955c.071 1.235.732 2.249 2.375 2.249h6.238c1.643 0 2.297-1.014 2.375-2.25l.31-4.954a2.244 2.244 0 0 0-2.233-2.393Zm-4.465.75h1.786a.451.451 0 0 1 0 .9h-1.786a.451.451 0 0 1-.447-.45c0-.246.203-.45.447-.45Zm.893 6.52a2.021 2.021 0 0 1-2.012-2.028c0-1.121.899-2.027 2.012-2.027s2.012.906 2.012 2.027a2.021 2.021 0 0 1-2.012 2.028Z"
                          fill="#fff"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </label>
          </div>

          <div
            style={{ paddingLeft: "20px", paddingRight: "20px", width: "100%" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                height: "110px",
                width: "100%",
              }}
            >
              <div style={{ position: "relative" }}>
                <Input
                  onChange={(e) => setName(e.target.value)}
                  color="black"
                  width="20vw"
                  value={name}
                  height="45px"
                  name="Nome do usuário*"
                  placeholder={"Insira o nome"}
                  borderColor={
                    nameError ? theme.colors.red3 : theme.colors.border
                  }
                />
              </div>

              <div style={{ position: "relative" }}>
                <Input
                  onChange={(e) => setMail(e.target.value)}
                  value={mail}
                  color="black"
                  width="20vw"
                  height="45px"
                  name="E-mail de acesso*"
                  placeholder={"Insira o e-mail"}
                  borderColor={
                    mailError ? theme.colors.red3 : theme.colors.border
                  }
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                height: "110px",
              }}
            >
              <div style={{ position: "relative" }}>
                <Input
                  name="Senha"
                  error={paswordError}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  color="black"
                  width="20vw"
                  placeholder="Mudar senha"
                  type={"password"}
                  borderColor={
                    paswordError ? theme.colors.red3 : theme.colors.border
                  }
                />
              </div>

              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  for="perfil"
                  className="textPrimaryLabelInput"
                  style={{ marginBottom: "15px" }}
                >
                  Permissão
                </label>
                <select
                  className="textPrimaryInput"
                  value={permission}
                  onChange={(text) => setPermission(text.target.value)}
                  id="perfil"
                  style={{
                    width: "20vw",
                    height: "50px",
                    borderRadius: "10px",
                    borderColor: permissionError
                      ? theme.colors.red3
                      : theme.colors.border,
                    paddingLeft: "15px",
                    color:
                      permission !== undefined ? "black" : theme.colors.grey2,
                  }}
                >
                  <option value="" disabled selected>
                    Selecione
                  </option>
                  <option style={{ color: "black" }} value="Administrativo">
                    Administrativo
                  </option>
                  <option style={{ color: "black" }} value="Visualizador">
                    Visualizador
                  </option>
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TableListUser;
