import React from "react";

import arrowLeft from "../../images/svg/arrowLeft.svg";
import arrowRight from "../../images/svg/arrowRight.svg";
import { theme } from "../../Global/styles/theme";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const containerPagination = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",

    marginBottom: "30px",
  };

  const arrow = {
    width: "30px",
    height: "30px",
    backgroundColor: theme.colors.background,
    borderRadius: "100%",
    border: `solid 1px ${theme.colors.grey1}`,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const buttom = {
    width: "30px",
    height: "30px",

    backgroundColor: theme.colors.background,
    borderRadius: "100%",
    border: `solid 1px ${theme.colors.grey1}`,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const buttomActive = {
    width: "30px",
    height: "30px",
    color: theme.colors.background,

    backgroundColor: theme.colors.colorPrimary,
    borderRadius: "100%",
    border: `solid 1px ${theme.colors.grey1}`,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const pagesToShow = 3;
  const adjacentPages = 1;

  const getPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  const pageNumbers = getPageNumbers();

  return (
    <div style={containerPagination}>
      {currentPage > 1 && (
        <button onClick={() => onPageChange(currentPage - 1)} style={arrow}>
          <img src={arrowLeft} />
        </button>
      )}
      {currentPage > 2 && (
        <>
          <button onClick={() => onPageChange(1)} style={buttom}>
            1
          </button>
          {currentPage > 2 && <span>...</span>}
        </>
      )}
      {pageNumbers.map((page) => {
        if (
          page < currentPage - adjacentPages ||
          page > currentPage + adjacentPages
        ) {
          return null;
        }
        return (
          <button
            key={page}
            onClick={() => onPageChange(page)}
            style={currentPage === page ? buttomActive : buttom}
          >
            {page}
          </button>
        );
      })}
      {currentPage < totalPages - 1 && (
        <>
          {currentPage < totalPages - 1 && <span>...</span>}
          <button onClick={() => onPageChange(totalPages)} style={buttom}>
            {totalPages}
          </button>
        </>
      )}
      {currentPage < totalPages && (
        <button onClick={() => onPageChange(currentPage + 1)} style={arrow}>
          <img src={arrowRight} />
        </button>
      )}
    </div>
  );
};

export default Pagination;
