import React, { createContext, useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import api from "../services/api";

const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const [searchingUsuario, setSearchingUsuario] = useState("");
  const [currentClient, setCurrentClient] = useState({});

  const [dashboards, setDashboards] = useState([]);
  const [updateUser, setUpdateUser] = useState(false);
  const [users, setUsers] = useState([]);

  const [totalPage, setTotalPage] = useState();
  const [clientId, setClientId] = useState("");
  const [lostpassword, setLostPassword] = useState("");

  const [embeddedSelected, setEmbeddedSelected] = useState("");
  const [filterName, setFilterName] = useState("");

  /* LIST */
  const onGetClient = async (page) => {
    try {
      const response = await api.get(`/client/employees/?page=${page}`);
      setUsers(response.data.data);
      console.log(response.data);
      setTotalPage(response.data.totalPages);
    } catch (err) {
      console.log(err);
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  /* LIST FILTER */
  const onGetFilterClient = async (page) => {
    try {
      const response = await api.get(
        `/client/employees/filter/${filterName}?page=${page}`
      );
      setUsers(response.data.data);
      setTotalPage(response.data.totalPages);
    } catch (err) {
      console.log(err);
      const message = err.response.data.message;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  /* DESTROY */
  const onDestroyClient = async () => {
    try {
      await api.delete(`/client/${clientId}`);

      onGetClient(1);

      toast.success("Conta deletada com sucesso", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      const message = err.response.data.message;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  /* UPDATE CLIENT */
  const onUpdateClient = async (data) => {
    try {
      await api.patch(`/client/${clientId}`, data);

      onGetClient(1);
    } catch (err) {
      const message = err.response.data.message;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <UserContext.Provider
      value={{
        searchingUsuario,
        setSearchingUsuario,
        currentClient,
        setCurrentClient,
        dashboards,
        setDashboards,
        clientId,
        setClientId,
        users,
        onGetClient,
        totalPage,
        onDestroyClient,
        onUpdateClient,
        updateUser,
        setUpdateUser,
        lostpassword,
        setLostPassword,
        embeddedSelected,
        setEmbeddedSelected,
        onGetFilterClient,
        filterName,
        setFilterName,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export function useGlobal() {
  const context = useContext(UserContext);
  return context;
}
