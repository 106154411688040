import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';

import ThowColumLayoutInventary from "../../layouts/ThowColumLayoutInventary";
import ModalPowerBi from "./components/ModalPowerBi";
import Sidebar from '../../components/Sidebar'
import HeaderDashboard from "../../components/HeaderDashboard";
import InputSelectFilterManager from '../../components/InputSelectFilterManager'
import CardBI from "../../components/CardBI/Index";

import { useGlobal } from "../../context/UserContext";
import { theme } from "../../Global/styles/theme"
import api from "../../services/api";

import close from "../../images/svg/close.svg"


const Dashboard = () => {
    const { searchingUsuario, dashboards, setDashboards } = useGlobal();
    const [showModal, setShowModal] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const [selectPowerBI, setSelectPowerBI] = useState({});
    const [typeUser, setTypeUser] = useState();
    const [updateDashboards, setUpdateDashboards] = useState(false);

    const rowFilters = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    }

    useEffect(() => {
        localStorage.getItem("@USER_TOKEN")
        if (!localStorage.getItem("@USER_TOKEN")) {
            localStorage.clear()
            window.location.href = "/"
        }
        getDashboards();
        if (localStorage.getItem("ControlReload") == 0) {
            localStorage.setItem("ControlReload", 1);

            setTimeout(setTimeout(window.location.reload(), 2000));
            localStorage.removeItem("ControlReload")
        }
    }, [updateDashboards])

    const getDashboards = async () => {
        try {
            const profile = await api.get("/client/profile/one");
            setTypeUser(profile.data.type);

            const id = profile.data.id;
            const business = profile.data.business

            const response = await api.post("/dashboard/getAllById", { id });
            const dashboardsResponse = response.data;

            const response2 = await api.post("/dashboard/getAllBusiness", { business });
            const dashboards2Response = response2.data;

            const combinedArray = [...dashboardsResponse, ...dashboards2Response];
            const uniqueObjects = Array.from(new Map(combinedArray.map(obj => [obj.id, obj])).values());

            setDashboards(uniqueObjects);
        } catch (error) {

        }
    }


    return (
        <ThowColumLayoutInventary
            colum1={<Sidebar page={"Dashboard"} />}
            colum2={
                <>
                    <div style={{ paddingRight: "4rem", paddingLeft: "4rem", paddingTop: "2.68rem", paddingBottom: "100px" }}>
                        <HeaderDashboard name="Meus Power BI" />
                        <div style={rowFilters}>
                            <InputSelectFilterManager />
                        </div>
                        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", marginTop: "20px", gap: "2%" }}>
                            {dashboards && dashboards.filter((user) => user.title.toLowerCase().includes(searchingUsuario.toLowerCase())).map((card, index) => (
                                <CardBI key={index} card={card} setSelectPowerBI={setSelectPowerBI} setShowModal={setShowModal} typeUser={typeUser} />
                            ))}
                        </div>
                    </div>

                    {/* MODAL DE AÇÃO */}
                    <Modal
                        dialogClassName="modal-filter-option"
                        show={showModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        scrollable={true}
                    >
                        <Modal.Header style={{ marginBottom: "-15px" }}>
                            <div style={{ display: "flex", height: "30px", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                <p className="textHeaderModalUser">Ações</p>
                                <div onClick={() => { setShowModal(false); setSelectPowerBI({}) }} style={{ cursor: "pointer", width: "20px", textAlign: "center" }}>
                                    <img src={close} alt='icone de fechar' />
                                </div>
                            </div>
                        </Modal.Header>
                        <Modal.Body style={{ display: "flex", flexDirection: "column", height: "240px", paddingLeft: "-15px", paddingRight: "-15px" }}>
                            <button onClick={() => { setModalEdit(true); setShowModal(false) }} style={{ border: "none", backgroundColor: "white", color: "black", borderBottom: `1px solid ${theme.colors.border}`, height: "60px" }}>
                                <b>Editar acesso BI</b>
                            </button>
                        </Modal.Body>
                    </Modal>

                    {/* MODAL DE EDIÇÃO */}
                    <ModalPowerBi setShowModal={setModalEdit} showModal={modalEdit} title={"Editar acesso BI"} data={selectPowerBI} setSelectPowerBI={setSelectPowerBI} setUpdateDashboards={setUpdateDashboards} />
                </>
            }
        >
        </ThowColumLayoutInventary >
    )

}

export default Dashboard