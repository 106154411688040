import React, { useState, useEffect } from "react";
import companyIcon from "../../logo.svg";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
const Sidebar = ({ page }) => {
  const navigate = useNavigate("");
  const [typeUser, setTypeUser] = useState("");

  const container = {
    width: "259px",
    position: "fixed",
    top: "0",
    zIndex: "2",
    height: "100vh",
    background: "white",
    border: "1px solid #D7D7D7",
    borderTopRightRadius: 17,
    borderBottomRightRadius: 17,
  };

  const containerImage = {
    display: "flex",
    justifyContent: "center",
    marginTop: "2.625rem",
    marginBottom: "-30px",
  };

  const containerItem = {
    display: "flex",
    alignItems: "center",
    width: "215px",
    borderRadius: "8px",
    paddingTop: "5px",
    paddingBottom: "8px",
    height: "43px",
    background: "#000",
    marginBottom: "21px",
    cursor: "pointer",
  };

  const containerItemNotChecked = {
    display: "flex",
    alignItems: "center",
    width: "215px",
    borderRadius: "8px",
    paddingTop: "2px",
    paddingBottom: "3px",
    height: "39px",
    background: "white",
    marginBottom: "21px",
    cursor: "pointer",
  };

  const image = {
    position: "relative",
    top: "1px",
    marginLeft: "11px",
    width: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const getProfile = async () => {
    const response = await api.get("/client/profile/one");
    if (response.status === 200) {
      setTypeUser(response.data.type);
    }
  }

  useEffect(() => {
    getProfile();
  }, [])

  const redirectToDashboardPage = () => {
    navigate("/dashboard");
  };
  const redirectToAccessPage = () => {
    navigate("/access");
  };
  const redirectToProfilePage = () => {
    navigate("/profile");
  };

  return (
    <div style={container}>
      <div style={containerImage}>
        <img src={companyIcon} style={{ width: "83%" }} alt="Logo" />
      </div>

      <div
        style={{ paddingLeft: "12px", paddingRight: "12px", marginTop: "70px" }}
      >
        <div
          onClick={() => redirectToDashboardPage()}
          style={page == "Dashboard" ? containerItem : containerItemNotChecked}
        >
          <div style={image}>
            {page === "Dashboard" ? (
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#a)" fill="#fff">
                  <path d="M19.493 12.5h-4.91a2.083 2.083 0 0 1-2.083-2.083V5.494a3.125 3.125 0 0 0-1.215-2.483 2.99 2.99 0 0 0-2.584-.556 11.426 11.426 0 0 0 .576 22.306 11.459 11.459 0 0 0 13.265-8.452 2.996 2.996 0 0 0-.556-2.588 3.183 3.183 0 0 0-2.493-1.221Zm1.029 3.3A9.342 9.342 0 1 1 9.21 4.48a.907.907 0 0 1 .796.178 1.058 1.058 0 0 1 .417.84v4.919a4.167 4.167 0 0 0 4.167 4.166h4.908a1.083 1.083 0 0 1 .85.417.92.92 0 0 1 .174.8Z" />
                  <path d="M24.636 7.756A10.493 10.493 0 0 0 17.273.387a2.187 2.187 0 0 0-1.37.078 2.084 2.084 0 0 0-1.3 1.93v4.897a3.125 3.125 0 0 0 3.125 3.125h4.916a2.09 2.09 0 0 0 1.992-2.66Zm-2.602.577h-4.31a1.042 1.042 0 0 1-1.042-1.041l-.008-4.889a.05.05 0 0 1 .026-.01h.027a8.409 8.409 0 0 1 5.913 5.94h-.606Z" />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M0 0h25v25H0z" />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#a)" fill="#787486">
                  <path d="M19.493 12.5h-4.91a2.083 2.083 0 0 1-2.083-2.083V5.494a3.125 3.125 0 0 0-1.215-2.483 2.99 2.99 0 0 0-2.584-.556 11.426 11.426 0 0 0 .576 22.306 11.459 11.459 0 0 0 13.265-8.452 2.996 2.996 0 0 0-.556-2.588 3.183 3.183 0 0 0-2.493-1.221Zm1.029 3.3A9.342 9.342 0 1 1 9.21 4.48a.907.907 0 0 1 .796.178 1.058 1.058 0 0 1 .417.84v4.919a4.167 4.167 0 0 0 4.167 4.166h4.908a1.083 1.083 0 0 1 .85.417.92.92 0 0 1 .174.8Z" />
                  <path d="M24.636 7.756A10.493 10.493 0 0 0 17.273.387a2.187 2.187 0 0 0-1.37.078 2.084 2.084 0 0 0-1.3 1.93v4.897a3.125 3.125 0 0 0 3.125 3.125h4.916a2.09 2.09 0 0 0 1.992-2.66Zm-2.602.577h-4.31a1.042 1.042 0 0 1-1.042-1.041l-.008-4.889a.05.05 0 0 1 .026-.01h.027a8.409 8.409 0 0 1 5.913 5.94h-.606Z" />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#787486" d="M0 0h25v25H0z" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </div>
          {page === "Dashboard" ? (
            <p className="textCheckedSidebar">Meus Power BI</p>
          ) : (
            <p className="textNotCheckedSidebar">Meus Power BI</p>
          )}
        </div>

        {typeUser === "Administrativo" &&
          <div
            onClick={() => redirectToAccessPage()}
            style={page == "access" ? containerItem : containerItemNotChecked}
          >
            <div style={image}>
              {page == "access" ? (
                <svg
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 15.333a3.833 3.833 0 1 1 0-7.666 3.833 3.833 0 0 1 0 7.666Zm0-5.75a1.917 1.917 0 1 0 0 3.833 1.917 1.917 0 0 0 0-3.833Zm5.75 12.459a5.75 5.75 0 1 0-11.5 0 .958.958 0 0 0 1.917 0 3.833 3.833 0 1 1 7.666 0 .958.958 0 0 0 1.917 0Zm0-14.375a3.833 3.833 0 1 1 0-7.667 3.833 3.833 0 0 1 0 7.667Zm0-5.75a1.916 1.916 0 1 0 0 3.832 1.916 1.916 0 0 0 0-3.832ZM23 14.375a5.756 5.756 0 0 0-5.75-5.75.958.958 0 1 0 0 1.917 3.833 3.833 0 0 1 3.833 3.833.958.958 0 0 0 1.917 0ZM5.75 7.667A3.833 3.833 0 1 1 5.75 0a3.833 3.833 0 0 1 0 7.667Zm0-5.75a1.917 1.917 0 1 0 0 3.833 1.917 1.917 0 0 0 0-3.833ZM1.917 14.375a3.833 3.833 0 0 1 3.833-3.833.958.958 0 0 0 0-1.917A5.756 5.756 0 0 0 0 14.375a.958.958 0 1 0 1.917 0Z"
                    fill="#fff"
                  />
                </svg>
              ) : (
                <svg
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 15.333a3.833 3.833 0 1 1 0-7.666 3.833 3.833 0 0 1 0 7.666Zm0-5.75a1.917 1.917 0 1 0 0 3.833 1.917 1.917 0 0 0 0-3.833Zm5.75 12.459a5.75 5.75 0 1 0-11.5 0 .958.958 0 0 0 1.917 0 3.833 3.833 0 1 1 7.666 0 .958.958 0 0 0 1.917 0Zm0-14.375a3.833 3.833 0 1 1 0-7.667 3.833 3.833 0 0 1 0 7.667Zm0-5.75a1.916 1.916 0 1 0 0 3.832 1.916 1.916 0 0 0 0-3.832ZM23 14.375a5.756 5.756 0 0 0-5.75-5.75.958.958 0 1 0 0 1.917 3.833 3.833 0 0 1 3.833 3.833.958.958 0 0 0 1.917 0ZM5.75 7.667A3.833 3.833 0 1 1 5.75 0a3.833 3.833 0 0 1 0 7.667Zm0-5.75a1.917 1.917 0 1 0 0 3.833 1.917 1.917 0 0 0 0-3.833ZM1.917 14.375a3.833 3.833 0 0 1 3.833-3.833.958.958 0 0 0 0-1.917A5.756 5.756 0 0 0 0 14.375a.958.958 0 1 0 1.917 0Z"
                    fill="#787486"
                  />
                </svg>
              )}
            </div>

            {page == "access" ? (
              <p className="textCheckedSidebar">Gerenciar acessos</p>
            ) : (
              <p className="textNotCheckedSidebar">Gerenciar acessos</p>
            )}
          </div>
        }

        <div
          onClick={() => redirectToProfilePage()}
          style={page === "profile" ? containerItem : containerItemNotChecked}
        >
          <div style={image}>
            {page === "profile" ? (
              <svg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#a)" fill="#fff">
                  <path d="M11.5 11.5a5.75 5.75 0 1 0 0-11.5 5.75 5.75 0 0 0 0 11.5Zm0-9.583a3.833 3.833 0 1 1 0 7.666 3.833 3.833 0 0 1 0-7.666Zm0 11.499a8.635 8.635 0 0 0-8.625 8.625.958.958 0 1 0 1.917 0 6.708 6.708 0 0 1 13.416 0 .958.958 0 0 0 1.917 0 8.635 8.635 0 0 0-8.625-8.625Z" />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M0 0h23v23H0z" />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#a)" fill="#787486">
                  <path d="M11.5 11.5a5.75 5.75 0 1 0 0-11.5 5.75 5.75 0 0 0 0 11.5Zm0-9.583a3.833 3.833 0 1 1 0 7.666 3.833 3.833 0 0 1 0-7.666Zm0 11.499a8.635 8.635 0 0 0-8.625 8.625.958.958 0 1 0 1.917 0 6.708 6.708 0 0 1 13.416 0 .958.958 0 0 0 1.917 0 8.635 8.635 0 0 0-8.625-8.625Z" />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#787486" d="M0 0h23v23H0z" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </div>
          {page === "profile" ? (
            <p className="textCheckedSidebar">Minha conta</p>
          ) : (
            <p className="textNotCheckedSidebar">Minha conta</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
